import React from 'react'
import { graphql } from 'gatsby'

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"

class PagePrivacyStatement extends React.Component {

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale;

    const title = "Privacy Statement"
    const content = data.wordpressPage.content

    return (
      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate>
          <MainContainer className="px-5 sm:px-0 pt-1 pb-16">
            <div className="content-legal w-full md:w-2/3" dangerouslySetInnerHTML={{ __html: content }}></div>
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }
}

export default PagePrivacyStatement

export const query = graphql`
  query($pId: Int!, $pType: String!) {
      wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
      content
    }
    headerImage: file(relativePath: { eq: "jesedu2020-plain-dark.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`